import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { WebsiteInput } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store/hooks'

type GeneralSettingsForm = Omit<WebsiteInput, 'id' | 'createdAt'>

export const GeneralSettingsTab = (): JSX.Element => {
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const websiteSave = useStoreActions((actions) => actions.model.websiteSave)

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm<GeneralSettingsForm>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        values: {
            elementDefinitions: '',
            name: '',
            previewURL: '',
            productionURL: '',
            ...selectedWebsite,
        },
    })

    const onSave = () => {
        if (selectedWebsite) {
            handleSubmit((data) => {
                websiteSave({
                    id: selectedWebsite.id,
                    createdAt: selectedWebsite.createdAt,
                    elementDefinitions: data.elementDefinitions,
                    name: data.name,
                    previewURL: data.previewURL,
                    productionURL: data.productionURL,
                })
            })()
        }
    }

    return (
        <Box display="flex" flexDirection="column" p={2} maxWidth={800} marginX={'auto'}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{t('pages.settingsPage.general.settings')}</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: t('pages.settingsPage.general.nameRequired'),
                        maxLength: { value: 150, message: t('pages.settingsPage.general.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('pages.settingsPage.general.name')}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="previewURL"
                    control={control}
                    rules={{
                        required: t('pages.settingsPage.general.previewURLRequired'),
                        maxLength: { value: 150, message: t('pages.settingsPage.general.previewURLMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('pages.settingsPage.general.previewURL')}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="productionURL"
                    control={control}
                    rules={{
                        required: t('pages.settingsPage.general.productionURLRequired'),
                        maxLength: { value: 150, message: t('pages.settingsPage.general.productionURLMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('pages.settingsPage.general.productionURL')}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="elementDefinitions"
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={20}
                                {...field}
                                fullWidth={true}
                                label={t('pages.settingsPage.general.elementDefinitions')}
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" onClick={onSave}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}
