import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ErrorConfig } from 'graphql/types'

export const useApolloClient = (
    graphQLServerURI: string,
    setError: (error: ErrorConfig) => void,
): ApolloClient<unknown> | undefined => {
    const authLink = new ApolloLink((operation, forward) => forward(operation))

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            console.error(JSON.stringify(graphQLErrors, null, '\t'))
            setError({
                title: 'graphQLErrorTitle',
                content: 'graphQLErrorContent',
                messages: graphQLErrors.map((error) => error.message),
            })
        } else if (networkError) {
            console.error(JSON.stringify(networkError, null, '\t'))
            setError({
                title: 'networkErrorTitle',
                content: 'networkErrorContent',
                messages: [networkError.message],
            })
        }
    })
    const httpLink = new HttpLink({ uri: graphQLServerURI, credentials: 'include' })

    const newLink = authLink.concat(httpLink)

    return new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink, newLink]),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
            mutate: {
                fetchPolicy: 'no-cache',
            },
        },
    })
}
