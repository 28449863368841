import { OperationVariables } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { Query } from 'graphql/types'
import { getGraphQLServerURI } from 'utils/getEnvConfig'

export interface FetchResult<Result = any> {
    errors: FetchError[]
    data: Result
}
export interface FetchError {
    message: string
    path: string[]
}

export const fetchData = async <TData = Query, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode,
    variables: TVariables,
) => {
    if (!query.loc) return

    try {
        // TODO: it may be neccessary to do const d = new Date() + d.getTime()
        const response = await fetch(getGraphQLServerURI(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ query: query.loc.source.body, variables }),
        })

        if (response?.status === 401) {
            window.location.href = '/#/NotLoggedIn'
            return undefined
        }
        if (!response) return undefined
        const data = (await response.json()) as FetchResult<TData>
        return data
    } catch (e) {
        console.log(e)
    }
}
